import type { Require } from 'modules/types'
import { NextSeo, NextSeoProps } from 'next-seo'
import React from 'react'

export interface PageProps extends Require<NextSeoProps, 'title'> {}

export const Page: React.FC<PageProps> = ({ children, ...seo }) => {
  return (
    <>
      <NextSeo {...seo} />
      {children}
    </>
  )
}
